.carousel {
  position: relative;
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.carousel-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  width: 100%;
}

.carousel-card-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 200px;
}

.icon-top-right {
  position: absolute;
  top: 5px;
  left: -60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  z-index: 10;
}

.carousel-card {
  background-color: white;
  padding: 30px 50px;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-align: left;
  width: 40%;
  height: 180px;
  margin-left: 16%;
  position: absolute;
  opacity: 1;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.slide-in-right {
  transform: translateX(100%);
  animation: slide-in-right 0.5s forwards;
}

.slide-in-left {
  transform: translateX(-100%);
  animation: slide-in-left 0.5s forwards;
}

.slide-out-right {
  transform: translateX(-100%);
  animation: slide-out-right 0.5s forwards;
}

.slide-out-left {
  transform: translateX(100%);
  animation: slide-out-left 0.5s forwards;
}

@keyframes slide-in-right {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out-right {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slide-out-left {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.carousel-title {
  color: #D81E05;
  top: '20px';
  font-family: 'Fixture Condensed Medium',sans-serif;
  font-weight: 500;
  line-height: 1.1;
  text-transform: uppercase;
  font-size: 34px;
}

.carousel-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.arrow-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  color: white;
  font-weight: bold;
  text-shadow: 0px 0px 5px black;
}

.arrow-button.left {
  left: 20px;
}

.arrow-button.right {
  right: 20px;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.indicator {
  background-color: #9CB0BC;
  border: none;
  border-radius: 50%;
  width: 8px;
  height: 11px;
  margin: 0 5px;
  cursor: pointer;
  opacity: 0.5;
}

.indicator.active {
  opacity: 1;
}

.indicator:focus {
  outline: none;
  box-shadow: 0 0 0 2px #007bff;
}

.carousel-button {
  background-color: #D81E05;
  border-radius: 8px;
  color: #FFFFFF;
  font-weight: 900;
  text-decoration: none;
  padding: 0.8rem 1.4rem;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  margin-top: 0.7rem;
  a {
    color: #FFFFFF !important;
    text-decoration: none;
  }
}